import PropTypes from 'prop-types';
import React, { Component } from 'react';
import classnames from 'classnames';

import { CONTACT_PHONE } from 'services/constants';
import './CareCoordinator.scss';

class CareCoordinator extends Component {
  renderText() {
    const className = 'contacts-info';
    switch (this.props.type) {
      case 'referral':
        return (
          <div className={className}>
            If you need help referring a patient to us, please have your office call me at{' '}
            <strong>
              {' '}
              <a className="gm-link" href={`tel:${CONTACT_PHONE}`}>
                {CONTACT_PHONE}
              </a>
            </strong>
          </div>
        );
      default:
        return (
          <div className={className}>
            If you’re unsure or need some help as you answer these questions, please contact us at{' '}
            <strong>
              {' '}
              <a className="gm-link" href={`tel:${CONTACT_PHONE}`}>
                {CONTACT_PHONE}
              </a>
            </strong>
            .
          </div>
        );
    }
  }

  render() {
    return (
      <div className={classnames('clinician-container', this.props.className)}>
        {this.renderText()}
      </div>
    );
  }
}

CareCoordinator.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};

export default CareCoordinator;
